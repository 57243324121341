import { ProjectDatum } from "./data";

function imggen(root: HTMLElement, data: ProjectDatum[]) {
  // TODO : replace with something like React
  const doGen = root.children.length === 0;

  for (let i = 0; i < data.length; i++) {
    const projectData = data[i];
    // Create or update the div.
    const div = doGen ? getImg(projectData) : (root.children[i] as HTMLElement);
    if (doGen) root.appendChild(div);
  }
}

function getImg(datapoint: ProjectDatum): HTMLImageElement {
  const img = document.createElement("img");
  img.id = "project-bg-img-" + datapoint.id;
  img.className = "project-bg-img";
  img.src = datapoint.img;
  return img;
}

export default imggen;
