import { h } from "preact";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoLetterMorphing() {
  return (
    <div>
      <p>
        This interactive typewriter project started with the question: "Where do
        letters come from?". And I immediately thought that they must obviously
        come from the letter before them...
      </p>
      <p>
        The characters you type, after being born from the preceding character,
        have a life on their own. They can breath, fly away... or change their
        meaning by morphing into another word suggested by Google.
      </p>
      <p>Made with Processing</p>
      <p>ECAL/Nathan Vogel</p>
    </div>
  );
}

export default InfoLetterMorphing;
