import { h } from "preact";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoBreakTheYoutube() {
  return (
    <div>
      <p>
        Break The Youtube modifies the Youtube interface, making it react to the
        sound of the video in real time.
      </p>
      <p>
        You can try a live demo{" "}
        <a href="https://nathanvogel.github.io/break-the-youtube-dist/">here</a>
        .
      </p>
    </div>
  );
}

export default InfoBreakTheYoutube;
