import { Point } from "paper";
import { scaleMM } from "./utils";

type AttractedPointProps = {
  position?: Point | null;
  attractionStrength?: number;
};

class AttractedPoint {
  public position: Point = new Point(0, 0);
  public velocity: Point = new Point(0, 0);
  public acceleration: Point = new Point(0, 0);
  public attractionStrength: number = 15;
  public frictionStrength: number = 0.03;

  constructor(props?: AttractedPointProps) {
    if (!props) return;
    if (props.position) this.position = props.position.clone();
  }

  applyForce(force: Point) {
    this.acceleration = this.acceleration.add(force);
  }

  attractTo(target: Point) {
    const dist = target.getDistance(this.position, false);
    const slowOnApproach = this.velocity.multiply(
      scaleMM(dist, 30, 400, -0.9, 0)
    );
    this.applyForce(slowOnApproach);

    const diff = target.subtract(this.position);
    const force = diff.normalize(
      scaleMM(dist, 0, 30, 0, this.attractionStrength)
    );
    this.applyForce(force);

    // Another more complex way to calculate an attractor
    // Truer to physics, but not what we want.
    // const magSq = target.getDistance(this.position, true);
    // const force = diff.normalize(this.attractionStrength).divide(minMax(magSq, 900, 40000));
  }

  onFrame() {
    const frictionForce = this.velocity.multiply(-this.frictionStrength);
    this.applyForce(frictionForce);
    // Apply acceleration to velocity
    this.velocity = this.velocity.add(this.acceleration);
    // Apply velocity to position
    this.position = this.position.add(this.velocity);
    this.acceleration = new Point(0, 0);
  }
}

export default AttractedPoint;
