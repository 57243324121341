import { TimelineMax, Power2 } from "gsap";
import "./about.css";

export function initAbout() {
  window.MyGlobals.isAboutOpen = false;
  const title = document.getElementById("about-title");
  const closeButton = document.getElementById("close-about");
  const container = document.getElementById("about-container");

  if (!title || !closeButton || !container) {
    console.error("Missing DOM node(s) of the about section");
    return;
  }

  title.addEventListener("click", toggleAbout);
  closeButton.addEventListener("click", closeAbout);

  const timeline = new TimelineMax({ paused: true });
  timeline
    .fromTo(
      container,
      0.28,
      { opacity: 0, transform: "translateY(9%) scale(1.2)" },
      {
        opacity: 1,
        transform: "translateY(0%) scale(1)",
        ease: Power2.easeInOut
      }
    )
    .fromTo(
      closeButton,
      0.15,
      { opacity: 0 },
      { opacity: 1, ease: Power2.easeInOut },
      0.15
    )
    .eventCallback("onReverseComplete", function() {
      container.style.display = "none";
    });

  function toggleAbout() {
    if (window.MyGlobals.isAboutOpen) closeAbout();
    else openAbout();
  }

  function openAbout() {
    if (container) container.style.display = "block";
    timeline.play();
    window.MyGlobals.isAboutOpen = true;
  }

  function closeAbout() {
    timeline.reverse();
    window.MyGlobals.isAboutOpen = false;
  }
}
