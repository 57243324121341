import { h } from "preact";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoMasques() {
  return (
    <div>
      <p>
        In partnership with the music label Microqlima, we (ECAL students) each
        created a video clip for one of 5 recently released songs. I chose
        L'Impératrice's Masques for its playful tone.
      </p>
      <p>Made in Cinema4D.</p>
      <p>
        ECAL/Nathan Vogel
        <br />
        Professor: Sami Benhadji
        <br />
        Music: L'Impératrice - Masques
        <br />
      </p>
    </div>
  );
}

export default InfoMasques;
