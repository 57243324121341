import { h } from "preact";
import Img01 from "../img/extra/albers-covers.jpg";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoAlbers() {
  return (
    <div>
      <p>
        As an hommage to Josef Albers, Bauhaus professor and author of
        'Interaction of Color', I animated two of his abstract album covers.
      </p>
      <p>
        The animations are driven in real-time by the sound of the albums
        themselves.
      </p>
      <p>Made in Unity3D</p>
      <img alt="Album covers by Josef Albers" src={Img01} />
      <p>
        ECAL/Nathan Vogel
        <br />
        Font: Beton Open, courtesy of David Tucker
      </p>
    </div>
  );
}

export default InfoAlbers;
