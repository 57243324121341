import { h } from "preact";
import Img01 from "../img/extra/soundla-screenshot01.jpg";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoSoundla() {
  return (
    <div>
      <p>
        A generative music video based on the Diffusion-Limited Aggregation
        (DLA) algorithm. The growth is driven by the sound, as invisible
        particles are generated from a 3D curl noise that rotates with the pitch
        of the music.
      </p>
      <p>
        Generated in SideFX Houdini
        <br />
        Rendered in Cinema4D
      </p>
      <img src={Img01} alt="Houdini SideFX screenshot" />
      <p>
        In order to have a fast and cheap render during this week-long workshop,
        I exported the particles positions from Houdini to Cinema4D using the
        Alembic format and rendered them using the Hair Renderer.
      </p>
      <p>
        ECAL/Nathan Vogel
        <br />
        Workshop led by Nicolas Barradeau at ECAL
        <br />
        Music: Yosi Horikawa - Bubbles
      </p>
    </div>
  );
}

export default InfoSoundla;
