import { h } from "preact";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoDeepMaps() {
  return (
    <div>
      <p>
        DeepMaps it a Firefox extension that lets you browse online maps as if
        they were hand-drawn in the 19th-century!
      </p>
      <p>
        DeepMaps reinterprets digital cartography through the aesthetics of 19th
        century maps. By simulating the traditional hand made process, it
        questions how we value manual artisanal techniques as opposed to highly
        automatable processes.
      </p>
      <p>
        The project is published as a Firefox extension that replaces
        OpenStreetMap tiles with neural network generated images in real time.
      </p>
      <blockquote class="twitter-tweet" data-lang="fr">
        <p lang="en" dir="ltr">
          student projects at my workshops get more and more sophisticated every
          year. this week at{" "}
          <a href="https://twitter.com/ecal_ch?ref_src=twsrc%5Etfw">@ecal_ch</a>
          , Nathan Vogel wrote a firefox extension which intercepts
          OpenStreetMap tiles in realtime, sends them to CycleGAN to be turned
          old-time style, then re-injects them back into the page{" "}
          <a href="https://t.co/AZBpRv0Zdf">pic.twitter.com/AZBpRv0Zdf</a>
        </p>
        &mdash; Gene Kogan (@genekogan){" "}
        <a href="https://twitter.com/genekogan/status/1048239707124125699?ref_src=twsrc%5Etfw">
          5 octobre 2018
        </a>
      </blockquote>
      <script async src="https://platform.twitter.com/widgets.js" />
      <p>
        DeepMaps was selected for the online gallery by the NeurIPS 2018
        Workshop on Machine Learning for Creativity and Design:{" "}
        <a href="http://www.aiartonline.com/design/nathan-vogel/">
          www.aiartonline.com/design/nathan-vogel/
        </a>
      </p>
      <p>
        ECAL/Nathan Vogel
        <br />
        Project started during a workshop led by Gene Kogan
        <br />
        Assistant: Tibor Udvari
      </p>
    </div>
  );
}

export default InfoDeepMaps;
