export enum Stage {
  Overview,
  Details
}

declare global {
  interface Window {
    MyGlobals: Globals;
  }
}

type Globals = {
  stage: Stage;
  isAboutOpen: boolean;
};
