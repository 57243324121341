import { h } from "preact";
import Img03 from "../img/extra/ioai/services.png";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoInternetofAI() {
  return (
    <div>
      <p>
        Internet of AI is a web service that lets anyone train ML-powered image
        classifiers and connect them to IFTTT (If This Then That). This way,
        users can easily create custom flows like "If I make this hand sign,
        play the next Spotify song" or "If I slouch in front of my computer,
        turn off my PC screen".
      </p>
      <p>Here're some of the services that can be automated thanks to IFTTT:</p>
      <img src={Img03} alt="Internet of AI services" />
      <p>Tech stack: ml5.js, IFTTT API, React, Redux</p>
      <p>ECAL/Nathan Vogel</p>
    </div>
  );
}

export default InfoInternetofAI;
