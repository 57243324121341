import Player, { Options } from "@vimeo/player";
import gsap, { TweenLite } from "gsap";
import { isDetectedMobile, clearContent } from "./utils";

type PlayerHolder = {
  player: Player;
  anim?: gsap.Animation;
};

const players: { [divId: string]: PlayerHolder } = {};

export function playVimeo(vimeoUrl: string, divId: string) {
  // Don't auto play on mobile, because it causes some weird issues
  // (video playing in background without sound on iPhone)
  // (video invisible until a tap is made)
  // etc.
  const autoplay = !isDetectedMobile();
  var options01: Options = {
    // id: vimeoId,
    url: vimeoUrl,
    autoplay: autoplay,
    transparent: true,
    color: "FFFFFF",
    // width: {video01_width}
  };

  players[divId] = { player: new Player(divId, options01) };

  TweenLite.set("#" + divId, { opacity: 0 });
  players[divId].player.on("loaded", function () {
    players[divId].anim = TweenLite.to("#" + divId, 0.4, { opacity: 1 });
  });
}

export function stopVimeo(divId: string) {
  const holder = players[divId];
  if (!holder) return;
  if (holder.anim && holder.anim.isActive) holder.anim.kill();

  if (holder.player) {
    // Sometimes, the player will keep loading the video and display it after
    // it should've been cleared, so we simply remove the iframe when it
    // does so. (it'll display a small iframe flash);
    holder.player.on("loaded", function () {
      console.log("Vimeo video was loaded after it should've been destroyed.");
      console.log("Re-clearing the iframe content...");
      const iframeContainer = document.getElementById(
        "details-iframe-container"
      );
      clearContent(iframeContainer);
    });
    holder.player.destroy().catch(function (e) {
      console.error("FAILED to terminate Vimeo", e);
    });
  }
  TweenLite.set("#" + divId, { opacity: 1 });
}
