import { h } from "preact";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoCityLoops() {
  return (
    <div>
      <p>
        A serie of three looping videos made in Cinema4D with Adrien Sgandurra
        and Lisa Rebeca. My role was to create the camera movements, render the
        city and integrate most of the landscapes made by Adrien Sgandurra.
        <br />
      </p>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/237769606?loop=1&color=ffffff"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%"
          }}
          frameBorder="0"
          allowFullScreen
          autoPlay={false}
          title="City Loop 2"
        />
      </div>
      <p>
        <br />
      </p>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/237769626?loop=1&color=ffffff"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%"
          }}
          frameBorder="0"
          allowFullScreen
          autoPlay={false}
          title="City Loop 3"
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
      <p>
        3D: Cinema4D
        <br />
        Post-production: After Effects
      </p>
      <p>
        ECAL/Lisa Rebeca, Adrien Sgandurra and Nathan Vogel
        <br />
        Teacher: Pauline Saglio
      </p>
    </div>
  );
}

export default InfoCityLoops;
