// Polyfills for IE, need to be imported first.
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./libs/polyfills";
import "normalize.css";
import "./reset.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { Expo, Linear, TweenLite } from "gsap";
import page from "page";
import qs from "qs";
import initFlashlight from "./flashlight";
import divgen from "./divgen";
import imggen from "./imggen";
import setDetails, {
  clearDetails,
  initScrollIndicators,
} from "./projectdetails";
import data from "./data";
import initCanvas from "./initCanvas";
import { removeClassFromChildren, clearClass } from "./utils";
import SELECTIONS from "./data-selections";
import { debounce } from "lodash";
import { Stage } from "./types";
import { initAbout } from "./about";

if (process.env.NODE_ENV === "production") {
  console.log(
    "%cWebsite made by Nathan Vogel",
    `
  font-family:Palatino, "Palatino Linotype", serif;
  font-size:18px;
  font-weight:normal;
  background: linear-gradient(#81A4FF, #FF9898);
  background: linear-gradient(180deg, #FF9898 0%, #E489B5 64.58%, #81A4FF 100%);
  border-radius: 2px;
  padding: 60px 24px 70px;
  box-sizing: border-box;
  text-align: center;
  color: white;
`
  );
  console.log(
    "%c                 https://nathanvogel.com",
    `
  font-family:Palatino, "Palatino Linotype", serif;
  font-size:15px;
  font-weight:normal;
`
  );
}

window.MyGlobals = window.MyGlobals || { stage: Stage.Overview };
let firstPageLoad = true;
const imgRoot = document.getElementById("image-wrapper");
const itemContainer = document.getElementById("project-items-container");
let selectionId =
  qs.parse(window.location.search, { ignoreQueryPrefix: true })["s"] ||
  "default";
if (selectionId !== "all" && !SELECTIONS[selectionId]) {
  console.warn("Invalid project selection");
  selectionId = "default";
}
let filteredData =
  selectionId === "all"
    ? Object.values(data)
    : SELECTIONS[selectionId]
        .map(function (projectId) {
          if (!data[projectId]) console.warn("Project not found:", projectId);
          return data[projectId];
        })
        .filter(function (d) {
          return Boolean(d);
        });
// Don't display an empty page for 404 ?
// if (filteredData.length === 0 ) filteredData = data;
function getProjectIndex(id: string): number {
  for (let i = 0; i < filteredData.length; i += 1) {
    if (filteredData[i].id === id) return i;
  }
  return -1;
}

const backLink = document.getElementById("back-button-a") as HTMLAnchorElement;
if (backLink) backLink.href = "/" + window.location.search;

// Init Paper.js asap
initCanvas();
// Init the flashlight effect
initFlashlight();
// Init the small scroll indicator for the project name bar
initScrollIndicators();
// Init the toggable about section.
initAbout();
// Init the background images + overview divs
if (itemContainer && imgRoot) {
  imggen(imgRoot, filteredData);
  divgen(itemContainer, filteredData);

  for (let i = 0; i < itemContainer.children.length; i += 1) {
    const div = itemContainer.children[i];
    const img = imgRoot.children[i];

    // WARNING: bad coding practices:
    // over-project events can also be triggered from divgen (in touch mode)
    div.addEventListener("mouseenter", function () {
      if (window.MyGlobals.stage === Stage.Details) return;
      clearClass("item-hover");
      div.classList.add("item-hover");
      img.classList.add("item-hover");
      let c_event = new CustomEvent("over-project");
      window.dispatchEvent(c_event);
    });
    div.addEventListener("mouseleave", function () {
      if (window.MyGlobals.stage === Stage.Details) return;
      div.classList.remove("item-hover");
      img.classList.remove("item-hover");
      let c_event = new CustomEvent("notover-project");
      window.dispatchEvent(c_event);
    });
  }
} else {
  console.error("Missing root DOM Node");
}

window.addEventListener(
  "resize",
  debounce(function () {
    if (itemContainer) divgen(itemContainer, filteredData);
  }, 120)
);

function transitionToOverview() {
  overviewTransition(true);
}

function transitionToDetails() {
  overviewTransition();
}

function overviewTransition(backTo?: boolean) {
  // The transition is unnecessary and costly when arriving on the page.
  if (backTo && firstPageLoad) return;

  const duration = 1.0;
  // We have to manually set pointerEvent earlier to avoid mouseleave changing
  // the background image.
  TweenLite.set("#overview-wrapper", {
    pointerEvents: backTo ? "all" : "none",
  });
  TweenLite.to("#overview-wrapper", duration, {
    ease: Expo.easeOut,
    transform: backTo ? "translateY(0px)" : "translateY(-80px)",
  });
  TweenLite.to("#overview-wrapper", duration / 6, {
    ease: Linear.easeInOut,
    opacity: backTo ? 1 : 0,
  });
}

page("/project/:projectId", function (context) {
  const projectId: string = context.params.projectId;
  const index = getProjectIndex(projectId);
  // If the project doesn't seem  to exist
  if (index < 0) {
    page.redirect("/404.html");
    return;
  }

  const imgRoot = document.getElementById("image-wrapper");
  const itemContainer = document.getElementById("project-items-container");
  if (!itemContainer || !imgRoot) {
    console.error("Missing DOM root node(s)");
    return;
  }

  // Prevent vh changes and reflows during scroll on mobile
  if (document.documentElement)
    document.documentElement.style.position = "static";

  window.MyGlobals.stage = Stage.Details;
  removeClassFromChildren(itemContainer, "item-hover");
  removeClassFromChildren(imgRoot, "item-hover");
  const div = itemContainer.children[index];
  const img = imgRoot.children[index];
  if (div) div.classList.add("item-selected");
  if (img) img.classList.add("item-selected");
  else console.error("Couldn't find image", index, projectId);
  let c_event = new CustomEvent("project-details", { detail: projectId });
  window.dispatchEvent(c_event);
  transitionToDetails();
  setDetails(projectId);
  firstPageLoad = false;
});

page("/", function () {
  const imgRoot = document.getElementById("image-wrapper");
  const itemContainer = document.getElementById("project-items-container");
  if (!itemContainer || !imgRoot) {
    console.error("Missing DOM root node(s)");
    return;
  }

  // Prevent scroll bouncing and URL bar hiding on mobile
  if (document.documentElement)
    document.documentElement.style.position = "fixed";

  window.MyGlobals.stage = Stage.Overview;
  // Display the item we just left (img + div)
  const lastSelected = document.getElementsByClassName("item-selected");
  for (let i = 0; i < lastSelected.length; i++) {
    lastSelected[i].classList.add("item-hover");
  }
  removeClassFromChildren(itemContainer, "item-selected");
  removeClassFromChildren(imgRoot, "item-selected");
  let c_event = new CustomEvent("overview");
  window.dispatchEvent(c_event);
  transitionToOverview();
  clearDetails();
  firstPageLoad = false;
});
// Start the router
page();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
