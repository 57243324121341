import { h } from "preact";
import Img01 from "../img/extra/flowlin-2.jpg";
import Img02 from "../img/extra/flowlin-dnd.jpg";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoFlowlin() {
  return (
    <div>
      <p>
        Flowlin is an interdisciplinary project started by a team of 6 students
        during the{" "}
        <a href="https://chi.camp" target="_blank" rel="noopener noreferrer">
          China Hardware Innovation Camp
        </a>
        . Designed in Switzerland, Manufactured in China.
      </p>
      <p>
        In this distracted and distracting world, getting the best out of our
        abilities is more difficult than ever. So we made Flowlin. You can
        simply touch Flowlin to indicate for how long you would like to focus
        and:
      </p>
      <ol>
        <li>
          Flowlin will turn on its gentle light to indicate to your co-workers
          that you do not want to be disturbed.
          <img src={Img01} alt="Flowlin 1" />
        </li>
        <li>
          It will also connect to your phone and computer to enable Do Not
          Disturb to block unnecessary notifications.
          <img src={Img02} alt="Flowlin 2" />
        </li>
      </ol>
      <p>
        A China Hardware Innovation Camp project
        <br />
        Interaction design: Nathan Vogel
        <br />
        Industrial design: Amandine Gini
        <br />
        Engineering: Axel Nilsson, Berk Olçum and Kevin Sin
        <br />
        Software development: Axel Nilsson and Nathan Vogel
        <br />
        Photography by: Adrien Sgandurra
        <br />
        With the help of: Marc Laperrouza, Marius Aeberli, Alain Bellet, Lison
        Christe, Cédric Duchêne, XGrant, La Forge, EPFL, ECAL, UNIL, Canton de
        Vaud and many others!
      </p>
    </div>
  );
}

export default InfoFlowlin;
