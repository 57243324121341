const SELECTIONS: { [selectionId: string]: Array<string> } = {
  default: [
    "relationswatch",
    "flowlin",
    //    "blendedstudy",
    // "thiryyearsofhacks",
    "breaktheyoutube",
    //    "audioreactiveroom",
    "maxbillposter",
    "albers",
    "net",
    // "sway",
    // "ehltimeline",
    "cityloops",
    "soundla",
    "masques",
    "deepmaps",
    // "switchbrain",
    // "internetofai"
    //    "seedscreen",
    "stockify",
    "lettermorphing",
    //    "hhmmss",
    //    "allwatchedover",
    //    "revolve",
  ],
  "epfl-astro-physics": [
    "relationswatch",
    "ehltimeline",
    //    "breaktheyoutube",
    //    "audioreactiveroom",
    // "maxbillposter",
    // "albers",
    "soundla",
    "deepmaps",
    "thiryyearsofhacks",
    // "switchbrain",
    //    "allwatchedover",
  ],
  field: [
    "relationswatch",
    //    "flowlin",
    //    "blendedstudy",
    //    "thiryyearsofhacks",
    //    "breaktheyoutube",
    //    "audioreactiveroom",
    "maxbillposter",
    "albers",
    "net",
    "sway",
    "ehltimeline",
    "cityloops",
    "soundla",
    "masques",
    "deepmaps",
    "switchbrain",
    "internetofai",
    //    "seedscreen",
    //    "lettermorphing",
    //    "stockify",
    //    "hhmmss",
    //    "allwatchedover",
    //    "revolve",
  ],
  antenna: [
    "relationswatch",
    "lettermorphing",
    //    "blendedstudy",
    //    "thiryyearsofhacks",
    //    "breaktheyoutube",
    //    "audioreactiveroom",
    "maxbillposter",
    "albers",
    "net",
    // "sway",
    // "ehltimeline",
    // "switchbrain",
    // "masques",
    //    "seedscreen",
    // "soundla",
    "stockify",
    "deepmaps",
    "flowlin",
    "cityloops",
    //    "hhmmss",
    //    "allwatchedover",
    //    "revolve",
  ],
};

export default SELECTIONS;
