export function scale(
  num: number,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number
): number {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}

export function scaleMM(
  num: number,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number
): number {
  const invertMM = out_max < out_min;
  return minMax(
    ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min,
    invertMM ? out_max : out_min,
    invertMM ? out_min : out_max
  );
}

export function minMax(num: number, min: number, max: number): number {
  return Math.min(Math.max(num, min), max);
}

export function clearContent(element: HTMLElement | null) {
  if (!element) return;

  for (let i = 0; i < element.children.length; i++) {
    const e = element.children.item(i);
    if (e) element.removeChild(e);
  }
}

export function removeClassFromChildren(
  element: HTMLElement | null,
  className: string
) {
  if (!element) return;
  for (let i = 0; i < element.children.length; i += 1) {
    const child = element.children[i];
    if (child) child.classList.remove(className);
  }
}

export function clearClass(className: string) {
  const items = document.getElementsByClassName(className);
  // Do it in reverse, because the HTMLCollection is updated live
  for (let i = items.length - 1; i >= 0; i -= 1) {
    items[i].classList.remove(className);
  }
}

/**
 * Returns the element itself or the first parent having the given class.
 * @param  baseElement   node to search with parents
 * @param  className className you're looking for
 * @return           the first found element or null
 */
export function findParentWithClass(
  baseElement: Element | null,
  className: string
) {
  let element: Element | null = baseElement;
  while (element) {
    if (element.classList.contains(className)) return element;
    element = element.parentElement;
  }
  return null;
}

export function isDetectedMobile() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}
