import ImgRelationsWatch from "./img/project-relations_watch.jpg";
import Img30yHacks from "./img/project-30y_hacks.png";
import ImgBTY from "./img/project-breaktheyoutube.jpg";
import ImgDeepMaps from "./img/project-deepmaps.jpg";
import ImgEHL from "./img/project-ehl.jpg";
import ImgNET from "./img/extra/net/09_DSC0259.jpg";
import ImgLibre from "./img/project-libre.jpg";
import ImgMasques from "./img/project-masques.jpg";
import ImgSeedscreen from "./img/project-seedscreen.jpg";
import ImgSoundla from "./img/project-soundla.jpg";
import ImgStockify from "./img/project-stockify.jpg";
import ImgHHMMSS from "./img/project-hhmmss.jpg";
import ImgSway from "./img/project-sway.jpg";
import ImgFlowlin from "./img/project-flowlin.jpg";
import ImgAllWatched from "./img/project-allwatched.jpg";
import ImgCityLoops from "./img/project-cityloops.jpg";
import ImgAlbers from "./img/project-albers.jpg";
import ImgMaxBillPoster from "./img/project-maxbillposter.png";
import ImgLetterMorphing from "./img/project-lettermorphing.png";
import ImgBlendedStudy from "./img/project-blendedstudy.jpg";
import ImgAudioReactiveRoom from "./img/project-audioreactiveroom.jpg";
import ImgIoAI from "./img/project-internetofai.png";

const data: { [id: string]: ProjectDatum } = {
  relationswatch: {
    name: "relations.watch",
    year: "2019",
    vimeoURL: "https://vimeo.com/362281287",
    // iframeURL: "https://relations.watch/e/1596198",
    img: ImgRelationsWatch,
    category: "web platform, data viz",
    id: "relationswatch",
    description:
      "A collaborative platform to gather knowledge about relations between public figures, companies, medias, etc.",
  },
  flowlin: {
    name: "Flowlin",
    year: "2017-19",
    img: ImgFlowlin,
    id: "flowlin",
    category: "hardware device",
    description:
      "A device to help you focus like a pro. Team project started at the China Hardware Innovation Camp",
  },
  blendedstudy: {
    name: "Blended Study Sàrl",
    year: "2016 - ongoing",
    img: ImgBlendedStudy,
    id: "blendedstudy",
    category: "app, e-learning",
    iframeURL: "http://blended.study",
    description:
      "In 2016, I co-founded Blended Study to develop a mobile platform to allow students and teachers to publish interactive educational content for university students.",
  },
  thiryyearsofhacks: {
    name: "30y_of_hacks",
    year: "2018",
    img: Img30yHacks,
    id: "thiryyearsofhacks",
    category: "website, data viz",
    iframeURL: "http://infomesh.org/hacks",
    description:
      "A web timeline about computer security for the Infomesh project",
  },
  breaktheyoutube: {
    name: "Break The Youtube",
    year: "2017",
    img: ImgBTY,
    category: "real-time animation, sound viz",
    description: "What if YouTube reacted to the music it plays?",
    id: "breaktheyoutube",
    vimeoURL: "https://vimeo.com/308484872/84ca52fc1a",
  },
  audioreactiveroom: {
    name: "Audio Reactive Room",
    vimeoURL: "https://vimeo.com/237775615/49347de1dd",
    year: "2016",
    img: ImgAudioReactiveRoom,
    category: "real-time, sound viz",
    id: "audioreactiveroom",
    description: "Real time sound visualization",
  },
  maxbillposter: {
    name: "Max Bill Poster",
    year: "2017",
    img: ImgMaxBillPoster,
    id: "maxbillposter",
    category: "real-time animation",
    iframeURL: "https://nathanvogel.com/works/max-bill-poster",
    description:
      "A poster designed by Max Bill, animated by Luca Sassoli de Bianchi and I",
  },
  albers: {
    name: "Reactive Albers",
    year: "2015",
    img: ImgAlbers,
    id: "albers",
    category: "real-time animation, sound viz",
    vimeoURL: "https://vimeo.com/237775610",
    description:
      "Album covers designed by Josef Albers, made audio reactive 50+ years later",
  },
  net: {
    name: "Project NET",
    category: "photography",
    year: "2016",
    img: ImgNET,
    id: "net",
    description: "Personal photography project about tech surveillance",
  },
  sway: {
    name: "Sway",
    year: "2017",
    img: ImgSway,
    id: "sway",
    category: "installation",
    vimeoURL: "https://vimeo.com/345598647/17673aec22",
    description:
      "ECAL Fitness audiovisual experience with 8-channel spatialized sound",
  },
  ehltimeline: {
    name: "EHL Timeline",
    year: "2017",
    img: ImgEHL,
    id: "ehltimeline",
    vimeoURL: "https://vimeo.com/345551844/1070a80162",
    category: "installation, data viz",
    description:
      "Triple-screen installation concept for the 125th anniversary of EHL",
  },
  cityloops: {
    name: "City Loops",
    year: "2017",
    img: ImgCityLoops,
    id: "cityloops",
    category: "video, 3d",
    vimeoURL: "https://vimeo.com/237769600/91a61f54cf",
    description: "Serie of 3 looping landscape videos",
  },
  masques: {
    name: "Masques",
    year: "2018",
    img: ImgMasques,
    id: "masques",
    category: "video, 3d",
    description: "Music video for L'Impératrice's song 'Masques'",
    vimeoURL: "https://vimeo.com/308389037/29354336db",
  },
  seedscreen: {
    name: "Seedscreen",
    year: "2018",
    img: ImgSeedscreen,
    id: "seedscreen",
    category: "real-time animation",
    description:
      "A digital curtain, bringing vegetal life to the consumption of ambient TV media",
  },
  soundla: {
    name: "Soundla",
    year: "2018",
    img: ImgSoundla,
    id: "soundla",
    category: "video, 3d, sound viz",
    vimeoURL: "https://vimeo.com/345250023/d2957ec48f",
    description: "Generative music video based on the DLA growth algorithm",
  },
  deepmaps: {
    name: "DeepMaps",
    year: "2018",
    img: ImgDeepMaps,
    id: "deepmaps",
    category: "app, ai",
    vimeoURL: "https://vimeo.com/299628717",
    description:
      "Browse online maps as if they were hand-drawn in the 19th century",
  },
  switchbrain: {
    name: "SwitchBrain",
    year: "2018",
    img: ImgLibre,
    id: "switchbrain",
    category: "video game, ai",
    vimeoURL: "https://vimeo.com/346454075/56803fbd1a",
    description:
      "Video game whose core gameplay mechanics are based on Machine Learning",
  },
  internetofai: {
    name: "Internet of AI",
    year: "2018",
    img: ImgIoAI,
    id: "internetofai",
    category: "web app, ai",
    vimeoURL: "https://vimeo.com/346526141/09f964d5d4",
    description:
      "An online service that connects machine learning to any smart device or online service thanks to IFTTT",
  },
  lettermorphing: {
    name: "Letter Morphing",
    year: "2015",
    img: ImgLetterMorphing,
    id: "lettermorphing",
    category: "interactive, typography",
    vimeoURL: "https://vimeo.com/308383125/f691abf77c",
    description: "Interactive typewriter that plays tricks on your words",
  },
  stockify: {
    name: "Stockify",
    year: "2018",
    img: ImgStockify,
    id: "stockify",
    vimeoURL: "https://vimeo.com/310372406",
    category: "app",
    description:
      "Live music service that follows the mood of the stock markets",
  },
  hhmmss: {
    name: "HHMMSS",
    iframeURL: "https://nathanvogel.github.io/chronos/",
    disableIframeInteractions: true,
    year: "2016",
    img: ImgHHMMSS,
    category: "real-time animation",
    id: "hhmmss",
    description: "Dynamic clock made out of generated planets",
  },
  allwatchedover: {
    name: "All Watched Over",
    year: "2018",
    img: ImgAllWatched,
    id: "allwatchedover",
    category: "graphic, data viz",
    description:
      "Infographic summarizing Adam Curtis' documentary: All Watched Over by Machines of Loving Grace",
  },
  // revolve: {
  //   name: "Revolve",
  //   year: "2018",
  //   img: ,
  // category: "VR video",
  //   youtubeVRIframeURL: "https://www.youtube.com/embed/XqkPmZYugQA",
  //   id: "revolve",
  //   description:
  //     "A 360 VR video"
  // },
};

export type ProjectDatum = {
  name: string;
  img: string;
  year: string;
  description: string;
  category?: string;
  id: string;
  vimeoURL?: string;
  iframeURL?: string;
  youtubeVRIframeURL?: string;
  disableIframeInteractions?: boolean;
};

export function getProjectDatum(id: string): ProjectDatum | null {
  return data[id] || null;
}

export default data;
