import { Rectangle } from "paper";
import { ProjectDatum } from "./data";
import { findParentWithClass, scaleMM, minMax, clearClass } from "./utils";
import { Stage } from "./types";

const CELL_WIDTH = Math.min(window.innerWidth, 360);
const CELL_HEIGHT = 220;

type Position = {
  x: number;
  y: number;
};

const offsetWrapper = document.getElementById("project-items-wrapper");
let lastTouchedDiv: Element | null = null;
let isFirstGeneration = true;

let mainBounds: Rectangle;
let currentOffsetX = 0;
let currentOffsetY = 0;
let targetOffsetX = 0;
let targetOffsetY = 0;

function divgen(root: HTMLElement, data: ProjectDatum[]) {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const positions = getPositions(width, height, data.length);
  const doGen = root.children.length === 0;
  mainBounds = new Rectangle(0, 0, width, height);
  // Make sure that those values stay fixed even if we modify another one.
  mainBounds.left = 0;
  mainBounds.right = CELL_WIDTH;
  mainBounds.top = 0;
  mainBounds.bottom = CELL_HEIGHT;

  for (let i = 0; i < data.length; i++) {
    const projectData = data[i];
    const position = positions[i];
    // Create or update the div.
    const div = doGen ? getDiv(projectData) : (root.children[i] as HTMLElement);
    div.style.transform = `translate(${position.x}px, ${position.y}px)`;
    div.style.width = CELL_WIDTH + "px";
    div.style.height = CELL_HEIGHT + "px";
    if (doGen) root.appendChild(div);
    // Keep checking to calculate the full width+height of our
    // virtual boundaries
    if ((mainBounds.left as number) > position.x) {
      mainBounds.left = position.x;
    }
    if ((mainBounds.right as number) < position.x + CELL_WIDTH) {
      mainBounds.right = position.x + CELL_WIDTH;
    }
    if ((mainBounds.top as number) > position.y) {
      mainBounds.top = position.y;
    }
    if ((mainBounds.bottom as number) < position.y + CELL_HEIGHT) {
      mainBounds.bottom = position.y + CELL_HEIGHT;
    }
  }
  root.style.width = mainBounds.width + "px";
  root.style.height = mainBounds.height + "px";
  isFirstGeneration = false;
}

function getDiv(datapoint: ProjectDatum): HTMLDivElement {
  const container = document.createElement("div");
  container.className = "project-item";
  container.id = "project-item-container-" + datapoint.id;
  const padder = document.createElement("div");
  padder.className = "project-item-padder";

  const a = document.createElement("a");
  a.href = "/project/" + datapoint.id + window.location.search;
  const title = document.createElement("h3");
  title.textContent = datapoint.name;
  a.appendChild(title);
  const description = document.createElement("p");
  description.textContent = datapoint.description;
  a.appendChild(description);
  const miniDescription = document.createElement("p");
  miniDescription.classList.add("category");
  miniDescription.textContent = datapoint.category || "";
  a.appendChild(miniDescription);
  padder.appendChild(a);
  container.appendChild(padder);

  return container;
}

function getPositions(
  width: number,
  height: number,
  posCount: number
): Position[] {
  var positions: Position[] = [];
  if (width / CELL_WIDTH > 1.5) {
    let rowCount = Math.ceil(height / CELL_HEIGHT);
    let columnCount = Math.ceil(width / CELL_WIDTH);
    // console.log("GRID:", rowCount, columnCount);
    let incrRow = false;
    while (rowCount * columnCount < posCount) {
      rowCount += 1;
      incrRow = !incrRow;
    }
    positions = spiral(columnCount, rowCount);
    positions.splice(posCount, positions.length - posCount);
  } else {
    positions = singleColumn(posCount);
    // If there's a single column and it's the initial page load,
    // we can easily scroll to top.
    if (isFirstGeneration) {
      targetOffsetY = -(posCount / 2) * CELL_HEIGHT;
    }
  }

  const totalWidth = CELL_WIDTH * getRangeSize(positions, "x");
  const totalHeight = CELL_HEIGHT * getRangeSize(positions, "y");
  scalePositions(positions, CELL_WIDTH, CELL_HEIGHT);
  offsetPositions(positions, totalWidth / 2, totalHeight / 2);
  // Snap positions to 0:0, because the spiral can produce asymetrical
  // results.
  let minX = totalWidth;
  let minY = totalHeight;
  for (let i = 0; i < positions.length; i++) {
    const p = positions[i];
    if (p.x < minX) minX = p.x;
    if (p.y < minY) minY = p.y;
  }
  offsetPositions(positions, -minX, -minY);
  return positions;
}

function getRangeSize<P extends Extract<keyof Position, string>>(
  array: Position[],
  key: P
): number {
  let min = 0;
  let max = 0;
  for (let i = 0; i < array.length; i++) {
    if (min > array[i][key]) min = array[i][key];
    if (max < array[i][key]) max = array[i][key];
  }
  if (array.length < 0) return 0;
  return 1 + max - min;
}

function scalePositions(
  positions: Position[],
  cellWidth: number,
  cellheight: number
) {
  for (let i = 0; i < positions.length; i++) {
    const p = positions[i];
    p.x *= cellWidth;
    p.y *= cellheight;
  }
}

function offsetPositions(
  positions: Position[],
  offsetX: number,
  offsetY: number
) {
  for (let i = 0; i < positions.length; i++) {
    const p = positions[i];
    p.x += offsetX;
    p.y += offsetY;
  }
}

function spiral(X: number, Y: number): Position[] {
  let x, y, dx, dy;
  let positions: Position[] = [];
  x = y = dx = 0;
  dy = -1;
  let t = Math.max(X, Y);
  let maxI = t * t;
  for (let i = 0; i < maxI; i++) {
    if (-X / 2 <= x && x <= X / 2 && -Y / 2 <= y && y <= Y / 2) {
      positions.push({ x, y });
    }
    if (x === y || (x < 0 && x === -y) || (x > 0 && x === 1 - y)) {
      t = dx;
      dx = -dy;
      dy = t;
    }
    x += dx;
    y += dy;
  }
  return positions;
}

function singleColumn(rowCount: number): Position[] {
  let positions: Position[] = [];
  for (let y = 0; y < rowCount; y += 1) {
    positions.push({ x: 0, y });
  }
  return positions;
}

window.addEventListener("mousemove", function(event) {
  // Don't move the items if the mousemove was probably triggered by
  // a touch event. Mostly when going back and forth between the
  // details and overview screen
  const timeSinceLastTouchStart = Date.now() - lastTouchTime;
  // (seen up to 34 on iPhone 5C)
  if (timeSinceLastTouchStart < 100) {
    console.log("Ignoring mousemove");
    return;
  }
  moveTo(event.clientX, event.clientY);
  touchmode = false;
});
window.addEventListener("touchstart", onTouchStart);
window.addEventListener("touchmove", onTouchMove);
window.addEventListener("touchend", onTouchEnd);

let lastTouchX: number | undefined = undefined;
let lastTouchY: number | undefined = undefined;
let speedX = 0;
let speedY = 0;
let touching = false;
let touchmode = false;
let lastTouchTime = 0;

function onTouchStart(ev: TouchEvent) {
  lastTouchTime = Date.now();
  touching = true;
  touchmode = true;
  speedX = 0;
  speedY = 0;
}

function onTouchMove(ev: TouchEvent) {
  if (ev.touches.length > 0) {
    touching = true;
    touchmode = true;
    if (window.MyGlobals.stage === Stage.Overview) {
      const x = ev.touches[0].clientX;
      const y = ev.touches[0].clientY;
      if (lastTouchX != null) speedX = lastTouchX - x;
      if (lastTouchY != null) speedY = lastTouchY - y;
      moveBy(speedX, speedY);
      lastTouchX = x;
      lastTouchY = y;
    }
    // Call preventDefault() to prevent any further handling
    // ev.preventDefault();
  }
}

function onTouchEnd(ev: TouchEvent) {
  lastTouchTime = Date.now();
  lastTouchX = undefined;
  lastTouchY = undefined;
  touching = false;
  touchmode = true;
}

function findCurrentElement() {
  // Now we also need to take care of styling the correct item
  // and unstyle the previous one + their respective background images.
  // Find the div
  const el = document.elementFromPoint(
    window.innerWidth / 2,
    window.innerHeight / 2
  );
  const div = findParentWithClass(el, "project-item");
  if (!div || !div.id || div.id.indexOf("project-item-container-") !== 0) {
    console.warn("Couldn't find a valid corresponding div");
    return;
  }
  // Only trigger when the selected item changes
  if (div === lastTouchedDiv) return;
  // Find the img
  const imgId = div.id.replace("project-item-container-", "project-bg-img-");
  const img = document.getElementById(imgId);
  if (!img) {
    console.warn("Couldn't find a corresponding image");
    return;
  }
  // Unstyle the previous ones
  // This code should be kept in sync with the similar mouse part in index.tsx
  clearClass("item-hover");
  div.classList.add("item-hover");
  img.classList.add("item-hover");
  let c_event = new CustomEvent("over-project");
  window.dispatchEvent(c_event);
  lastTouchedDiv = div;
  // lastTouchedImg = img;
}

// Clear the last touched div so that it can be hovered again more quickly
window.addEventListener("project-details", function() {
  lastTouchedDiv = null;
});

function moveTo(clientX: number, clientY: number) {
  if (mainBounds) {
    const W = window.innerWidth;
    const H = window.innerHeight;
    const scrollZoneW = W * 0.6;
    const scrollZoneH = H * 0.6;
    const marginW = (W - scrollZoneW) / 2;
    const marginH = (H - scrollZoneH) / 2;
    const cursorX = scaleMM(clientX, marginW, W - marginW, 0, scrollZoneW);
    const cursorY = scaleMM(clientY, marginH, H - marginH, 0, scrollZoneH);
    const extraWidth = Math.max(0, (mainBounds.width as number) - W + 100);
    const extraHeight = Math.max(0, (mainBounds.height as number) - H + 100);
    targetOffsetX = (cursorX / scrollZoneW) * extraWidth - extraWidth / 2;
    targetOffsetY = (cursorY / scrollZoneH) * extraHeight - extraHeight / 2;
  }
}

function moveBy(x: number, y: number) {
  targetOffsetX += x;
  targetOffsetY += y;
}

function onFrame() {
  requestAnimationFrame(onFrame);
  // No need to do so much calculation outside Overview
  if (window.MyGlobals.stage !== Stage.Overview) return;
  // Safety check
  if (!offsetWrapper) return;
  // If the user is touching, he has direct unsmoothed control over the grid
  if (!touching) {
    targetOffsetX += speedX;
    targetOffsetY += speedY;
    speedX *= 0.95;
    speedY *= 0.95;
  }

  // Speed is > 0 only when the user touched the screen
  if (Math.abs(speedX) < 0.01) speedX = 0;
  if (Math.abs(speedY) < 0.01) speedY = 0;
  if (speedX || speedY) {
    findCurrentElement();
  }

  // Keep in the bounds
  if (mainBounds.width)
    targetOffsetX = minMax(
      targetOffsetX,
      -mainBounds.width / 2 + CELL_WIDTH / 2,
      mainBounds.width / 2 - CELL_WIDTH / 2
    );
  if (mainBounds.height)
    targetOffsetY = minMax(
      targetOffsetY,
      -mainBounds.height / 2 + CELL_HEIGHT / 2,
      mainBounds.height / 2 - CELL_HEIGHT / 2
    );

  const diffX = targetOffsetX - currentOffsetX;
  const diffY = targetOffsetY - currentOffsetY;
  let totalDiff = Math.abs(diffX) + Math.abs(diffY);
  currentOffsetX += diffX * (touchmode ? 1 : 0.1);
  currentOffsetY += diffY * (touchmode ? 1 : 0.1);
  if (totalDiff > 1) {
    offsetWrapper.style.transform = `translate(${-currentOffsetX}px, ${-currentOffsetY}px)`;
  }
}
requestAnimationFrame(onFrame);

export default divgen;
