import gsap, { Bounce, Expo, Sine, TimelineMax, TweenLite } from "gsap";
import { getProjectDatum } from "./data";
import renderInfo from "./project-info";
import { clearContent } from "./utils";
import { playVimeo, stopVimeo } from "./vimeo";

let lastAnim: gsap.Animation | null = null;

export function initScrollIndicators() {
  const indicators = document.getElementsByClassName(
    "scroll-for-more-indicator"
  ) as HTMLCollectionOf<SVGElement>;
  for (let i = 0; i < indicators.length; i += 1) {
    const indicator = indicators[i];
    const tl = new TimelineMax({ repeat: -1, repeatDelay: 1.5 });
    // Adding a mini-rotation seems necessary for Firefox sub-pixel
    // rendering issues.
    // We use inline svg to be able to animate with GSAP, which is easier,
    // more powerful and more reusable
    // For example: yoyo:true requires hacks in SVG SMIL
    tl.staggerFromTo(
      indicator.children,
      0.7,
      {
        opacity: 0.25,
        transform: "scale(0.9, 0.9) rotate(0.10001deg)",
      },
      {
        opacity: 1,
        ease: Sine.easeIn,
        repeat: 1,
        yoyo: true,
        transform: "scale(1, 1) rotate(0.10001deg)",
      },
      0.25
    );
  }
}

function setDetails(projectId: string) {
  const nameElement = document.getElementById("project-name");
  const yearElement = document.getElementById("project-year");
  const descriptionElement = document.getElementById("project-description");
  const infoElement = document.getElementById("project-info");
  const iframeContainer = document.getElementById("details-iframe-container");
  if (
    !nameElement ||
    !yearElement ||
    !descriptionElement ||
    !iframeContainer ||
    !infoElement
  ) {
    console.error("Missing project details DOM node(s)");
    return;
  }

  const datum = getProjectDatum(projectId);
  if (!datum) {
    console.error("Couldn't find project with id " + projectId);
    return;
  }

  nameElement.textContent = datum.name;
  descriptionElement.textContent = datum.description;
  yearElement.textContent = datum.year;

  forceFinishCurrentAnim();
  lastAnim = TweenLite.to("#project-details", 0.5, {
    transform: "translateY(-88px)",
    ease: Expo.easeOut,
    delay: 0.9,
  }).eventCallback("onComplete", function () {
    document.body.classList.add("opened-details");
    animateInfoHint(true);
    if (datum.vimeoURL) {
      playVimeo(datum.vimeoURL, iframeContainer.id);
      iframeContainer.classList.add("video");
    } else if (datum.youtubeVRIframeURL) {
      const iframe = getYoutubeVRIframe(datum.youtubeVRIframeURL);
      iframeContainer.appendChild(iframe);
      iframeContainer.classList.add("video");
    } else if (datum.iframeURL) {
      const iframe = getIframe(datum.iframeURL);
      iframeContainer.appendChild(iframe);
      iframeContainer.classList.add("custom-website");
      if (datum.disableIframeInteractions) {
        iframe.style.pointerEvents = "none";
      }
    }
  });
  renderInfo(datum.id, infoElement);
}

function forceFinishCurrentAnim() {
  if (lastAnim && lastAnim.isActive) {
    lastAnim.eventCallback("onComplete", function () {});
    // Go to end with suppressEvents
    // lastAnim.progress(1, true);
    lastAnim.kill();
  }
}

const hasSeen = () => {
  const v = window.localStorage.getItem("user-last-seen-preview");
  if (v === "true") {
    setHasSeen();
    return true;
  }
  if (!v) return false;
  const lastSeen = parseInt(v);
  return Date.now() - lastSeen < 7 * 24 * 60 * 60 * 1000;
};
const setHasSeen = () =>
  window.localStorage.setItem("user-last-seen-preview", Date.now().toString());

const scrollElem = document.querySelector("#details-wrapper");
const onScroll = () => {
  const scrollTop = scrollElem ? scrollElem.scrollTop : 0;
  if (scrollTop > window.innerHeight / 3) {
    animateInfoHint(false);
    setHasSeen();
    if (scrollElem) scrollElem.removeEventListener("scroll", onScroll);
  }
};
if (scrollElem) scrollElem.addEventListener("scroll", onScroll);

let hintAnim: gsap.Animation;
const animateInfoHint = (on: boolean) => {
  if (on && !hasSeen()) {
    const tl = new TimelineMax({ repeat: 3 });
    hintAnim = tl
      .to(
        "#project-details",
        0.35,
        {
          ease: Sine.easeOut,
          transform: "translateY(-165px)",
        },
        3
      )
      .to("#project-details", 0.6, {
        ease: Bounce.easeOut,
        transform: "translateY(-88px)",
      });
    hintAnim.play();
  } else {
    if (hintAnim) {
      hintAnim.pause();
      hintAnim.seek(0);
      hintAnim.kill();
    }
  }
};

export function clearDetails() {
  forceFinishCurrentAnim();
  animateInfoHint(false);
  lastAnim = TweenLite.to("#project-details", 0.5, {
    transform: "translateY(40px)",
    ease: Expo.easeOut,
    delay: 0,
  });
  // If the user scrolled, we need to undo that while it's still possible.
  const wrapper = document.getElementById("details-wrapper");
  if (wrapper) wrapper.scrollTop = 0;
  document.body.classList.remove("opened-details");
  const iframeContainer = document.getElementById("details-iframe-container");
  if (iframeContainer) {
    stopVimeo(iframeContainer.id);
    iframeContainer.classList.remove("video");
    iframeContainer.classList.remove("custom-website");
  }
  clearContent(iframeContainer);
}

function getIframe(url: string) {
  const iframe = document.createElement("iframe");
  iframe.setAttribute("src", url);
  return iframe;
}

function getYoutubeVRIframe(url: string) {
  const iframe = document.createElement("iframe");
  iframe.setAttribute("src", url);
  iframe.setAttribute("frameborder", "0");
  iframe.setAttribute(
    "allow",
    "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
  );
  iframe.setAttribute("allowfullscreen", "true");
  return iframe;
}

export default setDetails;
