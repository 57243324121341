import { h } from "preact";
import Img01 from "../img/extra/net/01_DSC0837.jpg";
import Img02 from "../img/extra/net/02_DSC0379.jpg";
import Img03 from "../img/extra/net/03_DSC0258.jpg";
import Img04 from "../img/extra/net/04_DSC0852.jpg";
import Img05 from "../img/extra/net/05_DSC0816.jpg";
import Img06 from "../img/extra/net/06_DSC0859.jpg";
import Img07 from "../img/extra/net/07_DSC0820.jpg";
import Img08 from "../img/extra/net/08_DSC0835.jpg";
import Img09 from "../img/extra/net/09_DSC0259.jpg";
import Img10 from "../img/extra/net/10_DSC0245.jpg";
import Img11 from "../img/extra/net/11_DSC0834.jpg";
import Img12 from "../img/extra/net/12_DSC0362.jpg";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoNET() {
  return (
    <div>
      <p>
        In 2016, I made a photography book that explores the tension between my
        fascination for digital technology and the anxiety it triggers in me.
        This journey led me into the depths of datacenter cooling
        infrastructures.
      </p>
      <img alt="Project NET photography by Nathan Vogel" src={Img01} />
      <img alt="Project NET photography by Nathan Vogel" src={Img02} />
      <img alt="Project NET photography by Nathan Vogel" src={Img08} />
      <img alt="Project NET photography by Nathan Vogel" src={Img04} />
      <img alt="Project NET photography by Nathan Vogel" src={Img05} />
      <img alt="Project NET photography by Nathan Vogel" src={Img03} />
      <img alt="Project NET photography by Nathan Vogel" src={Img06} />
      <img alt="Project NET photography by Nathan Vogel" src={Img10} />
      <img alt="Project NET photography by Nathan Vogel" src={Img07} />
      <img alt="Project NET photography by Nathan Vogel" src={Img09} />
      <img alt="Project NET photography by Nathan Vogel" src={Img11} />
      <img alt="Project NET photography by Nathan Vogel" src={Img12} />
      <p>
        Project by ECAL/Nathan Vogel
        <br />
        Teachers: Daniela Droz and Ambrosetti Tonatiúh
      </p>
    </div>
  );
}

export default InfoNET;
