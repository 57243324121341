import { h } from "preact";
import Img01 from "../img/extra/sway/sway-rendu.jpg";
import Img02 from "../img/extra/sway/sway-plan.png";
import Img03 from "../img/extra/sway/sway-prototype01.jpg";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoSway() {
  return (
    <div>
      <p>
        Sway is an ECAL Fitness audiovisual experience that lets you interact
        with sound in space. 8 suspended speakers produce sound that moves with
        you in 360°.
      </p>
      <p>
        The sounds are mixed live in Ableton Live and spatialized in 8 channels
        with the Envelop plugin. The visuals are projected on the ground and
        everything reacts to the accelerometer in the custom-built platform.
      </p>
      <img src={Img01} alt="Sway - Render" />
      <img src={Img02} alt="Sway - Suspended speakers technical plan" />
      <p>Plans and 3D renders by Gabriel Follonier and Valentin Sieber</p>
      <img src={Img03} alt="Sway - Testing the prototype" />
      <p>
        Project by ECAL/Gabriel Follonier, Valentin Sieber and Nathan Vogel
        <br />
        Industrial Design: Gabriel Follonier and Valentin Sieber
        <br />
        Interaction Design: Nathan Vogel
        <br />
        Software development: Nathan Vogel
        <br />
        Sound: Valentin Sieber
        <br />
        Teachers: Vincent Jacquier, Christophe Gubéran, Florian Pittet
        <br />
        Assistants: Marion Aeby, Laura Perrenoud
      </p>
    </div>
  );
}

export default InfoSway;
