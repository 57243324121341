import * as paper from "paper";

function initCanvas() {
  const paperCanvas = document.getElementById(
    "paper-canvas"
  ) as HTMLCanvasElement;

  // Init Paper.js
  if (paperCanvas) paper.setup(paperCanvas);
  else console.error("Couldn't setup Paper.js");
}

export default initCanvas;
