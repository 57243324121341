import { h } from "preact";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoInfomesh() {
  return (
    <div>
      <p>
        URL: <a href="http://infomesh.org/hacks">infomesh.org/hacks</a>
      </p>
      <p>
        Information Mesh is a web platform celebrating the 30th anniversary of
        the World Wide Web that explores social, technical, cultural and legal
        facts throughout different interactive timelines.
      </p>
      <p>
        It was displayed at{" "}
        <a href="https://www2019.thewebconf.org/">The Web Conference 2019</a> in
        San Francisco.
      </p>
      <p>
        The timeline I realized shows events that forged the history of
        cybersecurity on the web. Hacker groups are forming, new mysterious
        computer viruses appear, governments attack each other and, of course,
        colossal amounts of data are gathered, stolen, lost or destroyed.
      </p>
      <p>
        All the events on this timeline come from the following Wikipedia
        webpages:{" "}
        <a href="https://en.wikipedia.org/wiki/List_of_security_hacking_incidents">
          List of security hacking incidents
        </a>{" "}
        and{" "}
        <a href="https://en.wikipedia.org/wiki/List_of_data_breaches">
          List of data breaches
        </a>
        . Events were then classified in categories and attributed values
        corresponding to the size of the hack. A visual for each event was then
        generated from this data. When more information on the event was needed,
        snippets of text found in the original sources of the Wikipedia pages
        were added.
      </p>

      <p>
        Design & dev: Nathan Vogel
        <br />
        Workshop at ECAL in collaboration with swissnex.
        <br />
        <br />
        ECAL Faculty
        <br />
        Vincent Jacquier, Pauline Saglio, Laura Perrenoud, Tibor Udvari, Pietro
        Alberti
        <br />
        <br />
        swissnex Team
        <br />
        Benjamin Bollmann, Mary Ellyn Johnson, Eryk Salvaggio
        <br />
        <br />
        Partners
        <br />
        Volker Eckl and Jan Gerlach,{" "}
        <a href="https://wikimediafoundation.org/">Wikimedia</a>
        <br />
        Amir Saber Esfahani, <a href="https://archive.org/">Internet Archive</a>
        <br />
        With the support of the <a href="https://www.vd.ch/">Canton of Vaud</a>
      </p>
    </div>
  );
}

export default InfoInfomesh;
