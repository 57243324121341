import { h } from "preact";
import Img01 from "../img/extra/ehl-setup.jpg";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoEHL() {
  return (
    <div>
      <p>
        In partnership with the École Hôtelière de Lausanne (EHL, Swiss
        Hospitality Management School), ECAL students were tasked with creating
        interactive timeline concepts for a triple-screen installation
        celebrating the 125th anniversary of EHL.
      </p>
      <p>
        I developed a fully functional proof-of-concept visual system, in which
        each student is represented by particle. Different formations can be
        chosen for each year, allowing to display a wide array of data.
      </p>
      <p>Tech stack: Three.js, Javascript</p>
      <p>
        My concept was then selected by EHL and adapted by Pietro Alberti to fit
        the new requirements. This is the final setup by Pietro Alberti, Florian
        Amoser and Alessandro Bolzoni:
      </p>

      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/255028331?loop=1&color=ffffff"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
          frameBorder="0"
          allowFullScreen
          autoPlay={false}
          title="City Loop 3"
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js" />

      <img alt="EHL Timeline final setup" src={Img01} />
      <p>
        ECAL/Nathan Vogel
        <br />
        Touch interface design and development: Romain Cazier
        <br />
        Supervised by Angelo Benedetto, Cyril Diagne and Vincent Jacquier
        <br />
        Music: Foeniks (aka ELektoBin) - Lost One <br />
        Final setup by: Pietro Alberti, Florian Amoser and Alessandro Bolzoni
      </p>
    </div>
  );
}

export default InfoEHL;
