import { h, render } from "preact";
import InfoBreakTheYoutube from "./info/InfoBreakTheYoutube";
import InfoMasques from "./info/InfoMasques";
import InfoEHL from "./info/InfoEHL";
import "./project-info.css";
import InfoSoundla from "./info/InfoSoundla";
import InfoCityLoops from "./info/InfoCityLoops";
import InfoDeepMaps from "./info/InfoDeepMaps";
import InfoSway from "./info/InfoSway";
import InfoNET from "./info/InfoNET";
import InfoAlbers from "./info/InfoAlbers";
import InfoRelationsWatch from "./info/InfoRelationsWatch";
import InfoMaxBill from "./info/InfoMaxBill";
import InfoSwitchBrain from "./info/InfoSwitchBrain";
import InfoInternetofAI from "./info/InfoInternetofAI";
import InfoStockify from "./info/InfoStockify";
import InfoFlowlin from "./info/InfoFlowlin";
import InfoLetterMorphing from "./info/InfoLetterMorphing";
import InfoInfomesh from "./info/InfoInfomesh";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function getContent(contentId: string) {
  switch (contentId) {
    case "breaktheyoutube":
      return <InfoBreakTheYoutube />;
    case "masques":
      return <InfoMasques />;
    case "ehltimeline":
      return <InfoEHL />;
    case "soundla":
      return <InfoSoundla />;
    case "cityloops":
      return <InfoCityLoops />;
    case "deepmaps":
      return <InfoDeepMaps />;
    case "sway":
      return <InfoSway />;
    case "net":
      return <InfoNET />;
    case "albers":
      return <InfoAlbers />;
    case "relationswatch":
      return <InfoRelationsWatch />;
    case "maxbillposter":
      return <InfoMaxBill />;
    case "switchbrain":
      return <InfoSwitchBrain />;
    case "internetofai":
      return <InfoInternetofAI />;
    case "stockify":
      return <InfoStockify />;
    case "flowlin":
      return <InfoFlowlin />;
    case "lettermorphing":
      return <InfoLetterMorphing />;
    case "thiryyearsofhacks":
      return <InfoInfomesh />;
    default:
      return <div />;
  }
}

function renderInfo(contentId: string, domNode: Element | Document) {
  let content = getContent(contentId);
  let toRender = (
    <div className="project-info" id={"info-" + contentId}>
      {content}
    </div>
  );
  // Small trick for compatibility with react-snap. To be able
  // to first hydrate the DOM if it contains pre-rendered nodes.
  if (domNode.hasChildNodes() && domNode.firstElementChild) {
    render(toRender, domNode, domNode.firstElementChild);
  } else {
    render(toRender, domNode);
  }
}

export default renderInfo;
