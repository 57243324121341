import { h } from "preact";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoStockify() {
  return (
    <div>
      <p>
        Stockify is a live music service that follows the mood of the stock
        markets. It plays happy music when stock prices are going up and sad
        music when they're going down.
      </p>
      <p>
        ECAL/Nathan Vogel
        <br />
        Workshop led by Dries Depoorter at ECAL
        <br />
        With footage by:
        <br />
        Indigo Production - Courbes https://vimeo.com/97409396
        <br /> and Danny Abel - DJFX Traders A - Dow Jones
        https://vimeo.com/35581803
        <br />
        Music by Jay Man - OurMusicBox http://www.youtube.com/c/ourmusicbox
        <br />
        No Momentum
        <br />
        Struggles Of Success
      </p>
    </div>
  );
}

export default InfoStockify;
