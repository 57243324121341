import { h } from "preact";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoRelationsWatch() {
  return (
    <div>
      <p>
        Access the website:{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.relations.watch"
        >
          www.relations.watch
        </a>
      </p>
      <p>
        This is a collaborative platform to gather knowledge about relations
        between public figures, companies, medias, etc. Its goal is to enable
        anyone to efficiently understand conflicts of interests, often at the
        heart of democratic or environmental issues.
      </p>
      <p>
        The project started as my final diploma project at ECAL and is now open
        source:{" "}
        <a href="https://github.com/nathanvogel/relations-watch">
          relations.watch on GitHub
        </a>
        .
      </p>
      <p>Tech stack: ArangoDB, React, Redux, d3, TypeScript, Wikidata</p>
      <p>ECAL/Nathan Vogel</p>
    </div>
  );
}

export default InfoRelationsWatch;
