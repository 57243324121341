import { h } from "preact";
import Img01 from "../img/extra/maxbill-drawing.jpg";

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

function InfoMaxBill() {
  return (
    <div>
      <p>
        During a workshop by Jürg Lehni, creator of Paper.js, I teamed up with
        Luca Sassoli de Bianchi to animate a combination of 2 drawings made by
        the swiss designer and architect Max Bill.
      </p>
      <img src={Img01} alt="Original Max Bill drawings" />
      <p>JavaScript canvas with Paper.js</p>
      <p>
        ECAL/Luca Sassoli de Bianchi and Nathan Vogel
        <br />
        Workshop led by Jürg Lehni
      </p>
    </div>
  );
}

export default InfoMaxBill;
