import * as paper from "paper";
import { Point, Color, Shape, Path, PathItem } from "paper";
import { TweenLite, Expo, TimelineMax } from "gsap";
import AttractedPoint from "./AttractedPoint";
import { Stage } from "./types";

const DARK_OVERLAY_OPACITY = 0.6;
const LIGHT_WIDTH = function() {
  return Math.min(window.innerWidth - 18, 510);
};
const LIGHT_HEIGHT = function() {
  return Math.min(window.innerHeight - 18, 380);
};

// Dirty fix to handle the opacity of the background on first page load
let shouldDarkenOverlay = false;

let lastFlashlightAnim: gsap.Animation | null = null;
function forceFinishFlashlightAnim() {
  if (lastFlashlightAnim && lastFlashlightAnim.isActive) {
    lastFlashlightAnim.kill();
  }
}

function initFlashlight() {
  // Create shapes
  let darkOverlay = getDarkOverlay();
  let light = getLightShape();
  let rectangle = getBoundsRectanglePath();
  let overlay = getOverlay(light, rectangle);

  // Attraction point
  let mousePos: Point = (paper.view.center as Point).clone();
  let attracted: AttractedPoint = new AttractedPoint({ position: mousePos });

  // Generation
  const updateOverlay = function() {
    if (overlay) overlay.remove();
    const previous = overlay;
    overlay = getOverlay(light, rectangle);
    // Preserve style and visibility
    overlay.style = previous.style;
    overlay.visible = previous.visible;
  };

  // Don't use Paper.js's event, because it doesn't fire if the page was
  // loaded as a touch screen and then switched to a real mousemove
  window.addEventListener("mousemove", function(ev) {
    if (window.MyGlobals.stage === Stage.Overview)
      mousePos = new Point(ev.clientX, ev.clientY);
  });

  // Put the touch event on the element rather than the window to avoid
  // blocking the scroll of the About me section
  const element = document.getElementById("project-items-wrapper");
  if (!element) {
    console.error("Couldn't get project item wrapper");
    return;
  }
  element.addEventListener(
    "touchmove",
    function(ev) {
      if (ev.touches.length > 0) {
        if (window.MyGlobals.stage === Stage.Overview) {
          // Center the flashlight
          centerLight();
          darkOverlay.opacity = DARK_OVERLAY_OPACITY;
          // Prevent scrolling bounce on iOS.
          ev.preventDefault();
        }
      }
    },
    { passive: false }
  );

  function centerLight() {
    if (paper.view.bounds.center) mousePos = paper.view.bounds.center.clone();
  }

  paper.view.on("resize", function() {
    centerLight();
    rectangle.bounds = paper.view.bounds;
    if (window.MyGlobals.stage !== Stage.Overview)
      light.bounds = paper.view.bounds;
    else {
      light.remove();
      light = getLightShape();
    }
    updateOverlay();
    darkOverlay.bounds = paper.view.bounds;
  });

  paper.view.on("frame", function() {
    let doUpdate = false;
    if (window.MyGlobals.stage !== Stage.Overview) return;

    // Apply position
    attracted.attractTo(mousePos);
    attracted.onFrame();
    // Only trigger a redraw if there's some velocity involved.
    if (
      (attracted.velocity && (attracted.velocity.length as number) > 0.0001) ||
      attracted.position.getDistance(light.position as Point, true) > 1
    ) {
      light.position = attracted.position.clone();
      doUpdate = true;
    }

    if (doUpdate) updateOverlay();
  });

  window.addEventListener("over-project", function(e: CustomEvent<string>) {
    // console.log(e.type);
    shouldDarkenOverlay = true;
    TweenLite.to(darkOverlay, 0.3, { opacity: DARK_OVERLAY_OPACITY });
  } as EventListener);
  window.addEventListener("notover-project", function(e: CustomEvent<string>) {
    // console.log(e.type);
    shouldDarkenOverlay = false;
    TweenLite.to(darkOverlay, 0.3, { opacity: 0 });
  } as EventListener);
  window.addEventListener("overview", function(e: CustomEvent<string>) {
    // console.log(e.type);

    if (!light.bounds || !light.position) {
      console.error("light has no bounds or no position");
      return;
    }
    attracted.position = (paper.view.center as Point).clone();
    const duration = 0.5;
    const tl = new TimelineMax();
    forceFinishFlashlightAnim();
    lastFlashlightAnim = tl
      .to(
        light.bounds,
        duration,
        { width: LIGHT_WIDTH(), height: LIGHT_HEIGHT() },
        0
      )
      .eventCallback("onUpdate", updateOverlay);

    // Don't use the timeline, so that it can be overriden by hover events
    TweenLite.to(darkOverlay, 0.3, {
      opacity: shouldDarkenOverlay ? DARK_OVERLAY_OPACITY : 0
    });
  } as EventListener);
  window.addEventListener("project-details", function(e: CustomEvent<string>) {
    // console.log(e.type);
    shouldDarkenOverlay = true;
    attracted.position = (paper.view.center as Point).clone();

    if (!light.bounds) {
      console.error("light has no bounds");
      return;
    }
    const ease = Expo.easeInOut;
    const duration = 0.6;
    const tl = new TimelineMax();
    forceFinishFlashlightAnim();
    lastFlashlightAnim = tl
      .to(
        light.bounds,
        duration,
        {
          top: 0,
          left: 0,
          right: window.innerWidth,
          bottom: window.innerHeight,
          ease
        },
        0
      )
      .to(darkOverlay, 0.6, { opacity: 0 }, 0.4)
      .eventCallback("onUpdate", updateOverlay);
  } as EventListener);
}

function getBoundsRectanglePath(): Path {
  const tmp = new Shape.Rectangle(paper.view.bounds);
  tmp.fillColor = new Color("white");
  const path = tmp.toPath(true);
  tmp.remove();
  return path;
}

function getLightShape(): Shape.Rectangle {
  // const lightShape = new Shape.Circle(paper.view.center as Point, 150);
  const lightShape = new Shape.Rectangle({
    position: paper.view.bounds.center,
    width: LIGHT_WIDTH(),
    height: LIGHT_HEIGHT()
  });
  lightShape.fillColor = new Color("black");
  return lightShape;
}

function getDarkOverlay(): Shape.Rectangle {
  const darkOverlay = new Shape.Rectangle(paper.view.bounds);
  darkOverlay.fillColor = new Color("black");
  darkOverlay.opacity = 0;
  return darkOverlay;
}

function getOverlay(light: Shape.Rectangle, rectangle: Path): PathItem {
  light.visible = true;
  rectangle.visible = true;
  const lightPath = light.toPath(true);
  lightPath.applyMatrix = false;
  const overlay = rectangle.subtract(lightPath, { insert: true });
  overlay.fillColor = new Color("white");
  lightPath.remove();
  light.visible = false;
  rectangle.visible = false;
  return overlay;
}

export default initFlashlight;
